<template>
  <a-layout class="transaction-view">
    <TitleBreadcrumb />
    <a-spin :spinning="loading" :tip="labels.loadingText">
      <div v-if="transaction" class="content-base container">
        <a-row type="flex" justify="space-between" :gutter="[16, 16]">
          <a-col>
            <h2 class="transaction-view-title">
              {{ labels.transactionDetails }}{{ transaction.code }}
            </h2>
          </a-col>
          <a-col>
            <a-button class="btn-print" icon="printer" @click="print">{{
              labels.print
            }}</a-button>
            <template v-if="transaction?.is_cancellable">
              <a-divider type="vertical" />
              <a-button type="danger" @click="confirmCancellation">
                {{ labels.cancel }}
              </a-button>
            </template>
          </a-col>
        </a-row>

        <a-card class="transaction-view-card" id="transaction-print-area">
          <a-row type="flex" justify="space-between" :gutter="[16, 16]">
            <a-col :xs="24" :md="12">
              <span class="transaction-view-card-label">{{
                labels.dateLabel
              }}</span>
              <span class="transaction-view-card-value">{{
                transaction.date | formatDate
              }}</span>
            </a-col>
            <a-col
              :xs="24"
              :md="12"
              :style="{ textAlign: isMobile ? 'left' : 'right' }"
            >
              <span class="transaction-view-card-label">{{
                labels.statusLabel
              }}</span>
              <span class="transaction-view-card-value">
                <a-tag :color="statusColor(transaction?.status?.toLowerCase())">
                  {{ statusText(transaction?.status?.toLowerCase()) }}
                </a-tag>
                <a-tooltip
                  v-if="
                    transaction?.status === 'REJECTED' &&
                    transaction?.reject_reason
                  "
                  trigger="click"
                  :title="transaction.reject_reason"
                >
                  <a-icon type="info-circle" class="text-danger" />
                </a-tooltip>
              </span>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between" :gutter="[16, 16]">
            <a-col :xs="24" :md="12">
              <span class="transaction-view-card-label">{{
                labels.typeLabel
              }}</span>
              <span class="transaction-view-card-value">{{
                transaction.type.name
              }}</span>
            </a-col>
            <a-col
              :xs="24"
              :md="12"
              :style="{ textAlign: isMobile ? 'left' : 'right' }"
            >
              <span class="transaction-view-card-label">{{
                labels.originLabel
              }}</span>
              <span class="transaction-view-card-value">{{
                transaction.origin.name
              }}</span>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between" :gutter="[16, 16]">
            <a-col :xs="24" :md="12">
              <span class="transaction-view-card-label">{{
                labels.currencyLabel
              }}</span>
              <span class="transaction-view-card-value">{{
                transaction.currency
              }}</span>
            </a-col>
            <a-col
              :xs="24"
              :md="12"
              :style="{ textAlign: isMobile ? 'left' : 'right' }"
            >
              <span class="transaction-view-card-label">{{
                labels.amountLabel
              }}</span>
              <span class="transaction-view-card-value">{{
                transaction.amount | formatNumber(2, ",", ".")
              }}</span>
            </a-col>
          </a-row>
          <template v-if="isTransfer">
            <a-row type="flex" justify="space-between" :gutter="[16, 16]">
              <a-col :xs="24" :md="12">
                <span class="transaction-view-card-label">{{
                  labels.destinationPhoneLabel
                }}</span>
                <span class="transaction-view-card-value">{{
                  transaction.destination_account_phone
                    | VMask("(###) ###-####")
                }}</span>
              </a-col>
              <a-col
                :xs="24"
                :md="12"
                :style="{ textAlign: isMobile ? 'left' : 'right' }"
              >
                <span class="transaction-view-card-label">{{
                  labels.destinationUserLabel
                }}</span>
                <span class="transaction-view-card-value">{{
                  `${transaction.destination_user.firstname} ${transaction.destination_user.lastname}`
                }}</span>
              </a-col>
            </a-row>
          </template>
          <template v-if="!isTransfer">
            <a-row type="flex" justify="space-between" :gutter="[16, 16]">
              <a-col :xs="24" :md="12">
                <span class="transaction-view-card-label">{{
                  labels.accountLabel
                }}</span>
                <span class="transaction-view-card-value">{{
                  transaction.account
                }}</span>
              </a-col>
              <a-col
                :xs="24"
                :md="12"
                :style="{ textAlign: isMobile ? 'left' : 'right' }"
              >
                <span class="transaction-view-card-label">{{
                  labels.accountTypeLabel
                }}</span>
                <span class="transaction-view-card-value">{{
                  labels.form.accountType[transaction.account_type]
                }}</span>
              </a-col>
            </a-row>
            <a-row
              v-if="!isDeposit"
              type="flex"
              justify="space-between"
              :gutter="[16, 16]"
            >
              <a-col :xs="24" :md="12">
                <span class="transaction-view-card-label">{{
                  labels.accountHolderLabel
                }}</span>
                <span class="transaction-view-card-value">{{
                  transaction.account_holder
                }}</span>
              </a-col>
              <a-col
                :xs="24"
                :md="12"
                :style="{ textAlign: isMobile ? 'left' : 'right' }"
              >
                <span class="transaction-view-card-label">{{
                  labels.identificationCardLabel
                }}</span>
                <span class="transaction-view-card-value">{{
                  transaction.identification_card | VMask("###-#######-#")
                }}</span>
              </a-col>
            </a-row>
            <a-row
              v-if="!isDeposit"
              type="flex"
              justify="space-between"
              :gutter="[16, 16]"
            >
              <a-col :xs="24" :md="12">
                <span class="transaction-view-card-label">{{
                  labels.emailHolderLabel
                }}</span>
                <span class="transaction-view-card-value">{{
                  transaction.email_holder
                }}</span>
              </a-col>
              <a-col
                :xs="24"
                :md="12"
                :style="{ textAlign: isMobile ? 'left' : 'right' }"
              >
                <span class="transaction-view-card-label">{{
                  labels.phoneLabel
                }}</span>
                <span class="transaction-view-card-value">{{
                  transaction.phone_holder | VMask("(###) ###-####")
                }}</span>
              </a-col>
            </a-row>
            <a-row type="flex" justify="space-between" :gutter="[16, 16]">
              <a-col v-if="transaction.reference" :xs="24" :md="12">
                <span class="transaction-view-card-label"
                  >{{ labels.referenceLabel }}
                </span>
                <span class="transaction-view-card-value">{{
                  transaction.reference
                }}</span>
              </a-col>
            </a-row>
            <template v-if="transaction.voucher?.length">
              <a-divider orientation="left" class="text-white">
                {{ labels.vouchersLabel }}
              </a-divider>
              <a-row type="flex" justify="center" :gutter="[16, 16]">
                <a-col :span="24">
                  <a-card size="small" class="card-vouchers">
                    <div class="voucher-container">
                      <template v-for="(v, idx) in transaction.voucher">
                        <span
                          v-if="v && !hiddenVouchers.includes(idx)"
                          :key="idx"
                          class="voucher-image-container"
                        >
                          <a-icon
                            type="eye"
                            class="voucher-image-container-icon noprint"
                            @click="() => showImage(getFile(v))"
                          />
                          <img
                            :src="getFile(v)"
                            :alt="v"
                            class="voucher-image"
                            :onError="
                              (error) => {
                                error.target.src = require('@/assets/images/no-image.png');
                              }
                            "
                          />
                        </span>
                      </template>
                    </div>
                  </a-card>
                </a-col>
              </a-row>
            </template>
          </template>
        </a-card>
      </div>
    </a-spin>
  </a-layout>
</template>
<script>
import loadingMixin from "@/mixins/loadingMixin";
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import { pageTitle } from "@/utils/utils";
import labels from "@/utils/labels";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import _ from "lodash";
import { DATE_FORMAT } from "../../../utils/utils";
import { path } from "@/api";

export const TYPES = {
  DEPOSIT: "Depósito",
  WITHDRAW: "Retiro",
};

export const ORIGINS = {
  TRANSFER: "Transferencia",
};

export default {
  components: { TitleBreadcrumb },
  name: "TransactionView",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.transactionView.subtitle),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.transactionView.subtitle,
      },
    ],
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.code) {
      next((vm) => {
        vm.handleFetchTransaction(to.params.code);
      });
    } else {
      next({ name: "transaction-list" });
    }
  },
  data() {
    return {
      labels: {
        ...labels.transactionView,
        cancel: labels.transactions.cancel,
        appName: labels.appName,
        table: labels.transactionList.table,
        form: labels.transactionForm,
      },
      transaction: null,
      loading: false,
      hiddenVouchers: [],
      TYPES,
      ORIGINS,
    };
  },
  computed: {
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 592;
    },
    customDetails() {
      // group by lottery
      const { details = [] } = this.transaction;
      const lotteries = _.uniqBy(
        _.cloneDeep(details).map((play) => play.lottery),
        "id"
      ).map((lottery) => ({
        ...lottery,
        isLottery: true,
        children: _.filter(
          _.cloneDeep(details),
          (play) => play.lottery.id === lottery.id
        ),
      }));
      return lotteries;
    },
    isDeposit() {
      return this.transaction?.type?.name === this.TYPES?.DEPOSIT;
    },
    isTransfer() {
      return (
        this.transaction?.type?.name === this.TYPES.WITHDRAW &&
        this.transaction?.origin?.name === this.ORIGINS.TRANSFER &&
        this.transaction?.destination_user?.id
      );
    },
  },
  methods: {
    ...mapActions("transactions", ["fetchClientTransaction", "cancelTransfer"]),
    expandIcon({ expandable }) {
      return expandable ? "" : " ";
    },
    countRecordByLottery(lottery) {
      return _.cloneDeep(this.transaction.details).filter(
        (play) => play.lottery.id === lottery.id
      ).length;
    },
    async handleFetchTransaction(code) {
      try {
        this.loading = true;
        const { data } = await this.fetchClientTransaction(code);
        this.transaction = data;
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        if (error.isAxiosError && error.response.status === 404) {
          this.$router.push({ name: "transaction-list" });
        }
      } finally {
        this.loading = false;
      }
    },
    getFile(file) {
      return path.client.files.single(file);
    },
    async print() {
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: ["/print.css"],
        timeout: 1000,
        autoClose: false,
        windowTitle: window.document.title,
      };
      await this.$htmlToPaper("transaction-print-area", options);
    },
    async showImage(voucher) {
      const image = new Image();
      image.src = voucher;
      image.style.maxWidth = "520px";
      image.id = "voucher-image";
      const w = window.open("", "_blank", "width=520,height=auto");
      w?.document.write(image.outerHTML);
      w?.document
        .getElementById("voucher-image")
        ?.addEventListener("error", () => {
          this.$message.error(this.labels.voucherLoadError);
          w?.close();
        });
    },
    confirmCancellation() {
      this.$confirm({
        title: this.labels.confirmCancellation.title,
        content: this.labels.confirmCancellation.content.replace(
          "{code}",
          this.transaction.code
        ),
        okText: this.labels.confirmCancellation.okText,
        okType: "danger",
        cancelText: this.labels.confirmCancellation.cancelText,
        onOk: () => {
          this.handleCancelTransfer(this.transaction);
        },
      });
    },
    async handleCancelTransfer(transaction) {
      this.loading = true;
      try {
        await this.cancelTransfer(transaction.code);
        this.$message.success(this.labels.cancelTransferSuccess);
        await this.handleFetchTransaction(transaction.code);
        this.checkSession();
      } catch (error) {
        this.$message.error(this.labels.cancelTransferError);
      } finally {
        this.loading = false;
      }
    },
  },
  filters: {
    formatDate(value) {
      return moment(value).format(DATE_FORMAT.MOMENT_DATE_TIME);
    },
  },
  beforeDestroy() {
    this.transaction = null;
    this.loading = false;
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.transaction-view {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  @media screen and (max-width: $screen-mobile) {
    max-width: 100% !important;
  }
  &-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 16px;
    color: color(--white);
  }
  .content-base {
    padding-top: 60px;
    @media screen and (max-width: $screen-mobile) {
      max-width: 100% !important;
    }
    .btn-print {
      color: color(c-hover);
      border-color: color(c-hover);
      background-color: color(c-secondary);
      transition: all 0.5s ease;
      &:hover {
        color: color(c-primary);
        border-color: color(c-hover);
        background-color: color(c-hover);
        transition: all 0.5s ease;
      }
    }
  }
  &-card {
    background-color: color(c-secondary);
    border-color: color(c-secondary);
    padding: 1.125rem;
    border-radius: 10px;
    @media screen and (max-width: $screen-mobile) {
      padding: 2.125rem 1rem;
      width: 100%;
    }
    &-title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
      line-height: 1.2;
      &.small {
        font-size: 1rem;
      }
    }
    &-subtitle {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 20px;
      color: color(--white);
      text-align: center;
    }
    &-label {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
    }
    &-value {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
      border-bottom: 1px dotted color(--white);
    }
    &-total-label {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
    }
    &-total-value {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
      border-bottom: 1px dashed color(--white);
    }
    .card-vouchers {
      background-color: color(c-secondary);
      border-style: dashed;
      padding: 0;
      border-radius: 10px;
      color: color(--white);
      @media screen and (max-width: $screen-mobile) {
        padding: 2.125rem 1rem;
        width: 100%;
      }
      .ant-card-body {
        padding: 0;
      }
      .voucher-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        align-items: center;
        gap: 15px;
        min-height: 240px;
        .voucher-image {
          &-container {
            position: relative;
            width: 240px;
            max-width: 240px;
            height: 240px;
            object-fit: cover;
            padding: 15px;
            &-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-40%, -50%);
              font-size: 2rem;
              color: color(c-hover);
              opacity: 0.8;
              transition: all 0.5s ease;
              background-color: color(c-primary);
              padding: 2px 4px;
              border-radius: 15%;
              &:hover {
                opacity: 1;
                transition: all 0.5s ease;
                cursor: pointer;
              }
            }
          }
          width: 240px;
          max-width: 240px;
          max-height: 100%;
          object-fit: cover;
          border: 5px solid color(--white);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  .ant-table {
    &:hover {
      transition: 0.5s ease;
      background: unset;
    }
    tr.ant-table-row:hover {
      transition: 0.5s ease;
      background: rgba(0, 0, 0, 0.25) !important;
    }
    tr:nth-child(2n) {
      transition: 0.5s ease;
      background: rgba(0, 0, 0, 0.2) !important;
    }
    tr.table-row-disabled,
    tr.table-row-disabled:hover {
      color: color(c-primary);
      background: color(danger-light) !important;
      font-weight: 600;
    }
    tr.table-row-disabled-hover:hover,
    tr.table-row-disabled-hover {
      background: none !important;
      font-weight: 700;
      font-size: 16px;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      transition: 0.5s ease;
      background: none !important;
    }
    &-tbody,
    &-thead {
      color: color(--white) !important;
      tr {
        th {
          color: color(--white) !important;
        }
      }
    }
    &-footer {
      background: transparent;
    }
    @media screen and (max-width: $screen-mobile) {
      &-small {
        border: none;
      }
      &-body {
        margin: 0 1px;
      }
    }
  }
  .text-danger {
    color: color(danger) !important;
  }
  .text-white {
    color: color(--white) !important;
  }
}
</style>
